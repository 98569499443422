export const actionTypes = {
  GET_LIST_THANKS_REQUEST: 'GET_LIST_THANKS_REQUEST',
  GET_LIST_THANKS_SUCESS: 'GET_LIST_THANKS_SUCESS',
  GET_LIST_THANKS_ERROR: 'GET_LIST_THANKS_ERROR',
  SET_PAGE_LIST_THANKS: 'SET_PAGE_LIST_THANKS',
  RESET_LIST_THANKS: 'RESET_LIST_THANKS'
};

export const requestListThanks = (cursor: string | null, limit: number) => ({
  type: actionTypes.GET_LIST_THANKS_REQUEST,
  cursor,
  limit
});

export const resetListThanks = () => ({
  type: actionTypes.RESET_LIST_THANKS
});
