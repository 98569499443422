import { put, takeLatest, call, all } from 'redux-saga/effects';

import { IactionTypeState } from 'src/interface/IActionTypeState';
import { ThanksApi } from '@src/api/ThanksApi';
import { IThanksResponse } from 'src/interface/IThanksState';
import { actionTypes } from './thanksAction';

function* getListThanks(action: IactionTypeState): any {
  const { cursor, limit } = action;
  try {
    const data: IThanksResponse = yield call(ThanksApi.getListThanks as any, cursor, Number(limit));

    if (data) {
      yield put({
        type: actionTypes.GET_LIST_THANKS_SUCESS,
        payload: data
      });
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIST_THANKS_ERROR
    });
  }
}

function* watchRequestGetListThanks() {
  yield takeLatest(actionTypes.GET_LIST_THANKS_REQUEST, getListThanks);
}

export function* thanksSaga() {
  yield all([watchRequestGetListThanks()]);
}
