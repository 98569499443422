import { actionTypes } from './thanksAction';
import { IThanksState, IDataThanks, TransactionObjectType } from 'src/interface/IThanksState';
import { actionTypes as actionTypeStatus } from 'src/activities/status/statusAction';
import { actionTypes as actionTypeComment } from 'src/activities/NewsEventDetail/DetailAction';

const initialState: IThanksState = {
  data: [],
  isLoadingListThanks: false,
  cursor: null,
  limit: 15
};

export function thanksReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_THANKS_REQUEST: {
      return {
        ...state,
        isLoadingListThanks: true
      };
    }
    case actionTypes.GET_LIST_THANKS_SUCESS: {
      let data: IDataThanks[] = [];
      data = [...state.data];

      data.push(...action.payload.transactions);

      return {
        ...state,
        data,
        isLoadingListThanks: false,
        cursor: action.payload.cursor,
        limit: action.payload.limit
      };
    }
    case actionTypes.GET_LIST_THANKS_ERROR: {
      return {
        ...state,
        data: [],
        isLoadingListStatus: false
      };
    }
    case actionTypes.SET_PAGE_LIST_THANKS: {
      return {
        ...state,
        page: action.payload
      };
    }
    case actionTypeStatus.LIKE_POST_STATUS_SUCCESS: {
      const { idPost, setLiked } = action;

      const data: IDataThanks[] = [...state.data];
      const index = data.findIndex((t: IDataThanks) => {
        return (
          (t.object_type === TransactionObjectType.Post ||
            t.object_type === TransactionObjectType.Status) &&
          t.object_id === idPost
        );
      });

      data[index].object_review.is_liked = setLiked;

      return {
        ...state,
        data
      };
    }
    case actionTypeComment.LIKE_COMMENT_SUCCESS: {
      const { id, like } = action;

      const data: IDataThanks[] = [...state.data];
      const index = data.findIndex((t: IDataThanks) => {
        return t.object_type === TransactionObjectType.Comment && t.object_id === id;
      });

      if (index === -1) {
        return state;
      }

      data[index] = { ...data[index] };

      if (data[index].object_review) {
        data[index].object_review.is_liked = like;
      } else {
        data[index].object_review = {
          is_bookmarked: false,
          is_clicked: false,
          is_followed: false,
          is_liked: true,
          is_read: false
        };
      }

      return {
        ...state,
        data
      };
    }
    case actionTypes.RESET_LIST_THANKS: {
      return initialState;
    }
    default:
      return state;
  }
}
